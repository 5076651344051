import styled from "styled-components";
import media from "styled-media-query";

import * as V from "styles/variables";

export const Header = styled.header.attrs({
  role: "banner",
})`
  align-items: center;
  background-color: var(--bg);
  // background-color: ${V.Color.yellow};
  color: var(--bgCode);
  color: ${V.Color.yellow};
  display: block;
  left: 0;
  position: fixed;
  transition: top 0.2s ease-in-out;
  right: 0;
  // text-transform: lowercase;
  top: 0;
  transition: background-color 0s;
  width: 100%;
  will-change: background-color;
  // z-index: ${V.ZIndex.header};

  ${media.greaterThan("medium")`
    align-items: center;
    background-color: var(--bg);
    // background-color: ${V.Color.yellow};
    // color: var(--bgCode);
    color: ${V.Color.yellow};
  `}
`;

export const Main = styled.div`
  align-items: center;
  display: flex;
  height: ${V.Height.headerSm};
  justify-content: space-between;

  ${media.greaterThan("medium")`
    height: ${V.Height.headerLg};
    align-items: center;
  `}
`;

export const Brand = styled.div`
  align-items: center;
  display: flex;

  > *:not(:last-child) {
    // margin-right: ${V.Space.sm};
  }
`;

export const Menu = styled.div`
  align-items: center;
  display: flex;
  margin: auto;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  ${media.lessThan("small")`
    // margin-right: -${V.Space.xs};
  `}

  > *:not(:last-child) {
    // margin-right: ${V.Space.xs};
  }
`;

export const Title = styled.h1`
  align-items: center;
  color: ${V.Color.black};
  color: ${V.Color.yellow};
  display: inline-block;
  font-size: 1.7rem;
  letter-spacing: 0.025rem;

  ${media.lessThan("350px")`
    display: none;
    align-items: center;
  `}

  ${media.greaterThan("small")`
    font-size: 2.1rem;
    align-items: center;
  `}
`;
