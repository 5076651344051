const menuLinks = [
  {
    label: "About",
    url: "/about/",
  },
  {
    label: "Essays",
    url: "/blog/",
  },
  // {
  //   label: "∞/21",
  //   url: "/21m/",
  // },
  {
    label: "Tip",
    url: "https://tip.suhailsaqan.com/",
  },
  {
    label: "Verify",
    url: "/verify/",
  },
  {
    label: "PGP",
    url: "/pgp/",
  },
];

export default menuLinks;
