import { css } from "styled-components";
import media from "styled-media-query";

import * as V from "styles/variables";

export const Title = css`
  & {
    color: var(--primaryColor);
    font-size: 35px;
    line-height: 1.1em;
    font-family: "Valkyrie B";
    font-weight: 300;

    ${media.greaterThan("medium")`
      font-size: 42px;
    `}
  }
`;

export const Subtitle1 = css`
  & {
    color: var(--secondaryColor);
    font-size: 1.5rem;
    letter-spacing: -0.45px;
    line-height: 1.5em;
    font-family: "Valkyrie B";
    font-weight: 300;

    ${media.greaterThan("medium")`
      font-size: 1.6rem;
    `}
  }
`;

export const Subtitle2 = css`
  & {
    color: var(--secondaryColor);
    font-size: 1.6rem;
    letter-spacing: -0.04px;
    line-height: 1.3em;
    font-family: "Valkyrie B";
    font-weight: 300;
  }
`;

export const Heading1 = css`
  & {
    color: var(--primaryColor);
    font-size: 2.7rem;
    font-weight: 700;
    letter-spacing: -0.022em;
    line-height: 1.3em;
    font-family: "Valkyrie B";

    ${media.greaterThan("medium")`
      font-size: 2.5rem;
    `}
  }
`;

export const Heading2 = css`
  & {
    color: var(--primaryColor);
    font-size: 2.6rem;
    font-weight: 600;
    letter-spacing: -0.04px;
    line-height: 1.5em;
    font-family: "Valkyrie B";

    ${media.greaterThan("medium")`
      font-size: 2.4rem;
    `}
  }
`;

export const Heading3 = css`
  & {
    color: var(--primaryColor);
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: -0.04px;
    line-height: 1.5em;
    font-family: "Valkyrie B";

    ${media.greaterThan("medium")`
      font-size: 2.3rem;
    `}
  }
`;

export const Heading4 = css`
  & {
    color: var(--primaryColor);
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.04px;
    line-height: 1.5em;
    font-family: "Valkyrie B";
    font-weight: 300;
  }
`;

export const Text1 = css`
  & {
    font-weight: 400;
    letter-spacing: 0.01em;
    font-family: "Valkyrie B";
    font-size: 16px;
    font-style: normal;
    line-height: 1.8;
    word-wrap: break-word;
    // text-shadow: 0px 0 #000000;
  }
  ${media.lessThan("medium")`
      font-size: 1.4rem;
    `}
`;

export const Text2 = css`
  & {
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: -0.14px;
    line-height: 1.55em;
    font-family: "Valkyrie B";
  }
`;

export const LinkTransition = css`
  & {
    border-bottom: 2px solid transparent;
    transition: border-color ${V.Transition.default};
    padding-bottom: 0.1rem;
    will-change: border-color;
    letter-spacing: 0.025em;
    font-family: "Valkyrie B";
    font-size: 15.5px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  &:hover {
    border-color: var(--highlightColor);
  }
`;

export const Link = css`
  & {
    color: ${V.Color.blue};
    ${LinkTransition}
  }
`;
