import React from "react";

import Container from "components/Container";

import * as S from "./styled";

const FooterData = () => {
  return (
    <S.FooterData>
      <Container>
        Copyright Ⓒ {new Date().getFullYear()} Suhail Saqan. All Rights Reserved
        {/* <a className="link" href="/policies">Política de Privacidade</a> */}
        {/* <a
          className="link"
          href="https://github.com/suhailsaqan/suhailsaqan.com/blob/master/LICENSE"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a> */}
      </Container>
    </S.FooterData>
  );
};

export default FooterData;
