import React from "react";
import ReactGA from "react-ga";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

import { getActiveTheme } from "utils/themes";

import menuLinks from "./content";
import * as S from "./styled";

const trackClick = (item) => {
  ReactGA.event({
    category: "Menu",
    action: "click",
    label: `Menu - ${item}`,
  });
};

const Menu = () => {
  return (
    <S.Menu>
      {menuLinks.map((link, i) => (
        <Link to={link.url}>{link.label}</Link>
      ))}
    </S.Menu>
  );
};

export default Menu;
